import React, { Component } from "react";

export interface IPromptProps {
    text: string;
    onRendered?: () => void;
}

export default class Prompt extends React.Component<IPromptProps, any> {
    public componentDidMount() {
        if (this.props.onRendered) {
            this.props.onRendered();
        }
    }

    public render() {
        return <div className="prompt">{this.props.text}</div>
    }
}
