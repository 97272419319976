import React, { Component } from "react";
import Screen, { IScreenData } from "./Screen";
import Scanlines from "./Scanlines";
import { IMeasurements } from "./TapeMeasure";
import Hacking from "./Hacking";
import Loader from "./Loader";

import Pictograms from "./minigames/Pictograms/Game";

import story from "./data/story.json";

interface IAppState {
    ready: boolean;

    currentScreen: number;
    previousScreen: number;
    screenWidth: number;
    // characterWidth: number;
    unlocked: boolean;
    measurements: IMeasurements;
}

interface IStoryConfig {
    hacking: boolean;
    pictogram: boolean;
}

interface IStory {
    config: IStoryConfig;
    screens: IScreenData[];
}

const MAX_COLUMNS = 54;

class App extends Component<any, IAppState> {
    private _story: IStory;
    private _columnWidth: number;
    private _columnCount: number;

    private _unlocked: boolean;

    constructor(props?: any) {
        super(props);

        this._story = story;
        
        this._columnCount = 0;
        this._columnWidth = 0;

        // initialize the first screen
        this.state = {
            ready: false,
            currentScreen: 1,
            previousScreen: null,
            screenWidth: null,
            unlocked: (this._story.config.hacking || this._story.config.pictogram) ? false : true,
            measurements: {
                charWidth: null,
                charHeight: null,
                lineHeight: null,
            },
        };

        this._changeScreen = this._changeScreen.bind(this);
        this._resizeScreen = this._resizeScreen.bind(this);
        this._onHacked = this._onHacked.bind(this);
    }

    public componentDidMount() {

    }

    public componentDidUpdate() {
        
    }

    public render() {
        const {
            ready,
            screenWidth,
         } = this.state;

        return (
            <main>
                {!ready && this._renderLoader()}
                {ready && screenWidth && this._renderScreen()}

                <Scanlines />
                {/* <TapeMeasure onMeasured={this._resizeScreen} /> */}
            </main>
        );
    }

    public _renderLoader(): React.ReactNode {
        return (
            <Loader onLoad={this._resizeScreen} />
        );
    }

    private _renderScreen(): React.ReactNode {
        const screenData = this._getScreenData();
        const {
            screenWidth,
            unlocked,
            measurements,
        } = this.state;
        
        if (!unlocked) {
            return this._renderMinigame();
        }

        return (
            <Screen
                data={screenData}
                previous={this.state.previousScreen}
                onChangeScreen={this._changeScreen}
                width={screenWidth}
                sound={false}
            />
        );
    }

    private _renderMinigame(): React.ReactNode {
        const {
            hacking,
            pictogram,
        } = this._story.config;

        const measurements = this.state.measurements;

        // hacking minigame
        if (hacking) {
            return (
                <Hacking
                    measurements={measurements}
                    onSuccess={this._onHacked}
                    sound={false}
                />
            );
        }

        // pictogram minigame
        if (pictogram) {
            return (
                <Pictograms
                    measurements={measurements}
                    onSuccess={this._onHacked}
                    sound={false}
                />
            );
        }

        return null;
    }

    private _getScreenData(): IScreenData {
        const {
            currentScreen,
            previousScreen,
        } = this.state;

        // find the current screen object
        const screen = story.screens.find((item) => item.id === currentScreen);

        return screen || {
            id: null,
            content: null,
        }
    }

    private _changeScreen(index: number) {
        const currentScreen = this.state.currentScreen;

        this.setState({
            currentScreen: index,
            previousScreen: currentScreen,
        });
    }

    private _resizeScreen(measurements: IMeasurements): void {
        this.setState({
            ready: true,
            screenWidth: measurements.charWidth * MAX_COLUMNS,
            measurements,
        });
    }

    private _onHacked(): void {
        this.setState({
            unlocked: true,
        });
    }
}

export default App;
