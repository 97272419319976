import React, { Component } from "react";
import Teletype, { ITeletypeData } from "./Teletype";

export interface IScreenProps {
    data: IScreenData;
    previous: number;
    onChangeScreen: (index: number) => void;
    width?: number;
    sound: boolean;
}

export interface IScreenData {
    id: number;
    content: ITeletypeData[];
    main?: boolean;
}

export interface IScreenLink {
    target: number;
    label: string;
}

interface IScreenState {
    renderMore: boolean;
    renderBack: boolean;
}

class Screen extends Component<IScreenProps, IScreenState> {
    private _main: boolean;

    constructor(props: IScreenProps) {
        super(props);

        this.state = {
            renderMore: false,
            renderBack: false,
        };

        this._main = !!this.props.data.main;

        this._onTeletypeDone = this._onTeletypeDone.bind(this);
    }

    public componentDidUpdate(prevProps: IScreenProps, prevState: IScreenState) {
        if (prevProps.data !== this.props.data) {
            this._reset();
        }
    }

    public render() {
        const {
            data,
            previous,
            onChangeScreen,
            width,
            sound,
        } = this.props;
        
        // screen width hasn't been calculated yet;
        // nothing to render
        if (!width) {
            return <div />;
        }

        const style: React.CSSProperties = {
            width,
        };

        return (
            <section className="screen" style={style}>
                <Teletype
                    data={data.content}
                    autostart={true}
                    sound={sound}
                    onDone={this._onTeletypeDone}
                    onNavigate={onChangeScreen}
                />

                { this.state.renderBack && this._renderBackButton() }
            </section>
        );
    }

    private _onTeletypeDone(): void {
        this.setState({
            renderBack: true,
        });
    }

    private _renderBackButton(): React.ReactNode {
        if (!this.state.renderBack || this._main) {
            return null;
        }

        const {
            data,
            previous,
            onChangeScreen,
        } = this.props;

        if (!previous || data.id === previous) {
            return;
        }

        const backData = [
            {
                text: "",
                target: null,
            },
            {
                text: "[Back]",
                target: previous,
            },
        ];

        return (
            <Teletype
                data={backData}
                autostart={true}
                onDone={null}
                onNavigate={onChangeScreen}
            />
        )
    }

    private _reset(): void {
        this.setState({
            renderBack: false,
            renderMore: false,
        });

        this._main = !!this.props.data.main;
    }
}

export default Screen;
