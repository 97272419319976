import React, { Component, CSSProperties } from "react";
import { IMeasurements } from "../../TapeMeasure";
import { Dialog } from "./Dialog";

import "../../assets/css/pictogram.scss";
import { Pixelboard } from "./Pixelboard";
import { Static } from "./Static";

// data for the pixelboard
import { Pictograms, Spacer } from "./Pictograms";

export interface IPictogramProps {
    measurements: IMeasurements;
    sound: boolean;
    onSuccess: () => void;
    onFailure?: () => void;
}

interface State {
    time: number;

    // game states
    firstrun: boolean;
    paused: boolean;
    success: boolean;
    
    // game data
    pictos: number[];
}

// screen size
const SCREEN_COLS = 54;

// timing constants
const DURATION = 10 * 1000;
const TICK = 10;

// game constants
const DIFFICULTY = 5;
const MAX_DIFFICULTY = 6;
const PICTO_COUNT = 8;
const BUTTON_COUNT = 8;
const BUTTON_ROW_COUNT = 2;

// pictogram data
const PICTOGRAMS = Pictograms;
const SPACER = Spacer;

export default class Game extends Component<IPictogramProps, State> {
    private _countdownTimerId: number = null;
    private _progressAnimTimerId: number = null;
    private _progressBarRef: React.RefObject<HTMLDivElement> = null;

    // game variables
    private _pictos: number[] = [];
    private _buttons: number[] = [];
    private _pixels: number[][] = [];

    constructor(props: IPictogramProps) {
        super(props);

        this._initializeGame();

        this.state = {
            time: DURATION,
            firstrun: true,
            paused: true,
            success: null,
            pictos: this._pictos,
        };

        this._progressBarRef = React.createRef<HTMLDivElement>();
        this._countdown = this._countdown.bind(this);
        this._togglePaused = this._togglePaused.bind(this);
        this._resizeProgressBar = this._resizeProgressBar.bind(this);
        this._onButtonClick = this._onButtonClick.bind(this);

        this._resetGame = this._resetGame.bind(this);
    }

    public componentDidMount(): void  {
        this._startCountdown();
    }

    public componentDidUpdate(prevProps: IPictogramProps, prevState: State): void {
        const {
            time,
            paused,
            success,
        } = this.state;

        if (paused) {
            return;
        }

        if (prevState.time !== time) {
            if (this._progressAnimTimerId) {
                window.cancelAnimationFrame(this._progressAnimTimerId);
            }

            this._progressAnimTimerId = window.requestAnimationFrame(() => {
                this._resizeProgressBar();
            });
        }
    }

    public render() {
        const charWidth = this.props.measurements.charWidth;
        const style: CSSProperties = {
            width: charWidth * SCREEN_COLS,
        };

        // screen width hasn't been calculated yet; nothing to render
        if (!charWidth) {
            return <div />;
        }

        const {
            firstrun,
            success,
        } = this.state;

        // render the instructions
        // render the outcome
        if (firstrun) {
            return this._renderInstructions();
        }

        // render the outcome
        if (success !== null) {
            return this._renderResult();
        }

        // render the game screen
        return (
            <section 
                className="screen pictogram"
                style={style}
            >
                {/* {this._renderCheats()} */}

                {this._renderCode()}
                {this._renderCountdown()}
                {this._renderButtons()}
                
            </section>
        );
    }

    private _renderInstructions() {
        const title = "Security detected";
        const text = "Enter unlock code before time expires.";

        const handleClick = () => {
            this.setState({
                firstrun: false,
                paused: false,
            });
        }

        return <Dialog title={title} text={text} onClick={handleClick} />;
    }

    private _renderCode(): React.ReactElement {
        return (
            <section className="code">
                {this._renderHeading()}

                <div className="display">
                    {this._renderPixelboard()}
                </div>
            </section>
        );
    }

    private _renderPixelboard(): React.ReactNode {
        const width = Math.floor(100 / MAX_DIFFICULTY) * DIFFICULTY;
        const style: CSSProperties = {
            width: `${width}%`, 
        };

        return (
            <div className="pixelboard" style={style}>
                <Pixelboard data={this._generatePixelboardData()} />
            </div>
        );
    }

    private _generatePixelboardData(): number[][] {
        if (!this._pictos.length) {
            return null;
        }

        const glyphs = this._pictos.map((value, index) => {
            return PICTOGRAMS[value];
        });

        let data = [];

        for (let y = 0; y < SPACER.length; y++) {
            let row: number[] = [];

            for (let z = 0; z < glyphs.length; z++) {
                const glyph = glyphs[z] || SPACER;
                row = row.concat(glyph[y]);
            }

            data.push(row);
        }

        return data;
    }

    private _renderHeading(): React.ReactNode {
        const title = "callback code";
        const letters = title.split("");

        return (
            <h3>
                {letters.map((value, index) => {
                    return (<span key={index}>{value}</span>);
                })}
            </h3>
        );
    }

    private _renderCheats(): React.ReactNode {
        if (this.state.success === null) {
            return (
                <div onClick={this._togglePaused}>Toggle paused</div>
            );
        }

        return (
            <div onClick={this._resetGame}>Reset</div>
        );
    }

    // main button rendering
    private _renderButtons(): React.ReactNode {
        const rows = this._divide(this._buttons, BUTTON_ROW_COUNT);

        return (
            <section className="buttons">
                {rows.map((row, index) => {
                    return this._renderButtonRow(row, index);
                })}
            </section>
        );
    }

    private _divide(source: any[], num: number): any[] {
        if (num < 2) {
            return [source];
        }

        const length = Math.ceil(source.length / num);
        return [source.slice(0, length)].concat(this._divide(source.slice(length), num - 1));
    }

    private _renderButtonRow(buttons: number[], index: number): React.ReactNode {
        // split the button array into BUTTON_ROW_COUNT
        return (
            <div className="row" key={index}>
                {buttons.map((value) => {
                    return this._renderButton(value);
                })}
            </div>
        );
    }

    private _renderButton(index: number): React.ReactNode {
        const handleTouchStart = (e: React.TouchEvent<HTMLButtonElement>) => {
            this._onButtonClick(index);
        };

        const handleTouchEnd = (e: React.TouchEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.currentTarget.blur();
        }

        const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            this._onButtonClick(index);
            e.currentTarget.blur();
        };

        const data = PICTOGRAMS[index];

        return (
            <button
                className="button"
                key={index}
                onClick={handleClick}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
            >
                <Pixelboard data={data} />
                <div className="hover" />
            </button>
        );
    }


    // main button actions
    private _onButtonClick(char: string | number): void {
        const { 
            pictos: pictograms,
            paused,
        } = this.state;

        // if (paused) {
        //     return;
        // }

        this._checkAnswer(char);
    }

    private _checkAnswer(char: string | number) {
        const { 
            pictos,
            success,
        } = this.state;

        // check the answer
        let index = null;
        
        for (let i = 0; i < pictos.length; i++) {
            const pictogram = pictos[i];
            
            // if the item is null, move to the next one
            if (pictogram === null) {
                continue;
            }

            // it's not a match, so we can stop checking
            if (pictogram !== char) {
                break;
            }

            // if we've made it this far, we have a match!
            index = i;
        }

        // we found a match!
        if (index !== null) {
            // null the specified element in the array
            pictos[index] = null;

            // we've matched the last element
            let done = success;
            let paused = false;
            if (index === pictos.length - 1) {
                done = true;
                paused = true;
            }

            // update state
            this.setState({
                pictos,
                success: done,
                paused,
            });
        }
    }


    // countdown rendering (progress bar & timer)
    private _renderCountdown() {
        return (
            <section className="countdown">
                {this._renderProgressBar()}
                {this._renderTimer()}
            </section>
        );
    }





    // progress bar related functions
    private _renderProgressBar(): React.ReactNode {
        const time = this.state.time;
        const percentage = Math.floor((time / DURATION) * 100) * 0.3;

        const style: CSSProperties = {
            width: `${percentage}%`,
        };

        return (
            <div className="progress">
                <div className="bar" ref={this._progressBarRef} />
            </div>
        );
    }

    private _resizeProgressBar() {
        const ref = this._progressBarRef.current;

        if (ref) {
            const time = this.state.time;
            const percentage = Math.floor((time / DURATION) * 100);
            
            if (percentage < 30) {
                ref.classList.add("danger");
            } else {
                ref.classList.remove("danger");
            }

            ref.style.width = `${percentage}%`;
        }
    }

    private _resetProgressBar() {
        if (this._progressAnimTimerId) {
            window.cancelAnimationFrame(this._progressAnimTimerId);
            this._progressAnimTimerId = null;
        }

        const ref = this._progressBarRef.current;

        if (ref) {
            ref.classList.remove("caution");
            ref.classList.remove("danger");
            ref.classList.remove("critical");

            ref.style.width = null;
        }
    }

    private _renderTimer(): React.ReactNode {
        // we can skip the last digit of the time
        // it's far too accurate for our purposes
        const time = Math.floor(this.state.time / 10);

        // format time
        // desired format: 00:00:00
        let mins = "00";
        let secs = this._padTime(Math.floor(time / 100).toString(), 2);
        let mils = this._padTime(time.toString().substr(-2, 2), 2);

        const content = `${mins}:${secs}:${mils}`;

        return (
            <div className="timer">
                {content}
            </div>
        );
    }

    private _padTime(time: string, length: number): string {
        while (time.length < length) {
            time = "0" + time;
        }

        return time;
    }

    // success of failure
    private _renderResult(): React.ReactNode {
        const {
            success,
        } = this.state;

        if (success === null) {
            return null;
        }

        if (!success) {
            return this._renderFailure();
        }

        const title = success ? "code accepted" : "incorrect code";
        const className = success ? "success" : "failure";
        const message = success ? "System unlocked" : "Intrusion detected";
        const label = success ? null : "Reset";

        const handleClick = () => {
            if (success) {
                return;
            }

            this._resetGame();
        }

        return (
            <Dialog
                title={title}
                text={message}
                className={className}
                buttonLabel={label}
                onClick={handleClick}
            />
        );
    }

    private _renderFailure(): React.ReactElement {
        const title = "incorrect code";
        const className = "failure";
        const message = "Intrusion detected";
        const label = "Reset";

        const handleClick = () => {
            this._resetGame();
        };

        return (
            <div className="static">
                <Static />
                <Dialog
                    title={title}
                    text={message}
                    className={className}
                    buttonLabel={label}
                    onClick={handleClick}
                />
            </div>
        );
    }

    // timer related functions
    private _startCountdown() {
        if (this._countdownTimerId) {
            window.clearInterval(this._countdownTimerId);
            this._countdownTimerId = null;
        }

        this._countdownTimerId = window.setInterval(() => {
            this._countdown();
        }, TICK);
    }

    private _togglePaused() {
        const paused = !this.state.paused;

        this.setState({
            paused,
        });
    }

    private _endCountdown() {
        window.clearInterval(this._countdownTimerId);
        this._countdownTimerId = null;

        // time has run out!
        this.setState({
            paused: true,
            success: false,
        });
    }

    private _clearCountdown() {
        window.clearInterval(this._countdownTimerId);
        this._countdownTimerId = null;
    }

    private _countdown() {
        if (this.state.paused) {
            return;
        }

        let time = this.state.time;

        // if time is up, end the countdown
        if (time === 0) {
            this._endCountdown();
            return;
        }

        // if there's still time, continue
        if (time > 0) {
            time -= TICK;
        }

        this.setState({
            time,
        });
    }


    // game preparation
    // array shuffling, cf. https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
    private _shuffle(source: any[]) {
        let copy = [...source];

        for (let i = copy.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
            [copy[i], copy[j]] = [copy[j], copy[i]]; // swap elements
        }

        return copy;
    }

    private _initializeGame() {
        // generate the array of numbers from 0 - (PICTO_COUNT - 1)
        const indices = Array.from(Array(PICTO_COUNT).keys());

        // since we'll always have more buttons than pictograms
        // we'll create the button array, and make the pictos a
        // subsection of that
        const shuffled = this._shuffle(indices);
        
        const buttons = shuffled.slice(0, BUTTON_COUNT);
        this._buttons = buttons;

        const pictos = this._shuffle(buttons);
        this._pictos = pictos.slice(0, DIFFICULTY);
    }

    private _resetGame() {
        this._clearCountdown();
        this._resetProgressBar();

        this._initializeGame();

        this.setState({
            time: DURATION,
            paused: false,
            success: null,
            pictos: this._pictos,
        }, () => this._startCountdown());
    }
}