import React, { Component } from "react";
import { IScreenData }  from "./Screen";
import { IMeasurements } from "./TapeMeasure"; 
import Teletype, { ITeletypeData } from "./Teletype";
import Log from "./Log";
import Prompt from "./Prompt";

import "./assets/css/hacking.scss";

export interface IHackingProps {
    measurements: IMeasurements;
    sound: boolean;
    onSuccess: () => void;
    onFailure?: () => void;
}

interface IHackingState {
    attempts: number;

    // render state
    headingRendered: boolean;
    attemptsRendered: boolean;
    columnsRendered: boolean;
    promptRendered: boolean;

    ready: boolean;

    history: string[];
    prompt: string;

    success: boolean;

    cheating: boolean;
}

const COLUMN_COUNT = 2;
const COLUMN_ROWS = 16;
const ATTEMPTS_INDICATOR = " *";
const HEX_PREFIX = "0x";
const TEXT_ROW_LENGTH = 12;

const SCREEN_COLS = 54;
const SCREEN_ROWS = 16; // * linheight = screen height
const LOG_ROWS = 15; // * linheight = log height

const SUCCESS_TIMEOUT = 1500;

export default class Hacking extends Component<IHackingProps, IHackingState> {
    private _screen: IScreenData = {
        id: 0,
        content: [
            {
                text: "Welcome to ROBCO Industries (TM) Termlink",
                target: null
            },

            {
                text: "Password Required",
                target: null
            }
        ]
    };

    private _successScreen: IScreenData = {
        id: 99998,
        content: [
            {
                text: ">Password accepted.",
                target: null
            }
        ]
    };

    private _failureScreen: IScreenData = {
        id: 99999,
        content: [
            {
                text: ">Password rejected.",
                target: null
            },

            {
                text: "",
                target: null
            },

            {
                text: "[Reset]",
                target: -1
            },
        ]
    };

    private _timerId: number = null;

    // game related members
    private _words = [
        "shot",
        "hurt",
        "sell",
        "give",
        "gear",
        "sent",
        "fire",
        "glow",
        "week",
        "ones",
        "sick",
    ];
    private _answer: number;
    private _offsets: string[];
    private _text: string;
    private _wordIndices: number[];
    private _wordLength: number;

    // history & prompt-related members
    private _promptRef: React.RefObject<HTMLElement> = null;
    private _historyRef: React.RefObject<HTMLElement> = null;
    private _history: string[];

    constructor(props: IHackingProps) {
        super(props);

        // setup the game
        this._initializeGame();

        // initialize state
        this.state = {
            attempts: 5,
            headingRendered: false,
            attemptsRendered: false,
            columnsRendered: false,
            promptRendered: false,
            ready: false,
            history: [],
            prompt: "",
            success: null,
            cheating: false,
        };

        // set up references
        this._promptRef = React.createRef<HTMLElement>();

        // method binding
        this._onHeadingRendered = this._onHeadingRendered.bind(this);
        this._onAttemptsRendered = this._onAttemptsRendered.bind(this);
        this._onColumnsRendered = this._onColumnsRendered.bind(this);
        this._onPromptRendered = this._onPromptRendered.bind(this);
        this._onAcceptedRendered = this._onAcceptedRendered.bind(this);
        this._onLockoutRendered = this._onLockoutRendered.bind(this);
        this._renderStaticColumns = this._renderStaticColumns.bind(this);
        this._onSucceed = this._onSucceed.bind(this);
        this._onFail = this._onFail.bind(this);
        this._onKeyPress = this._onKeyPress.bind(this);
        this._resetGame = this._resetGame.bind(this);
        this._toggleCheats = this._toggleCheats.bind(this);
    }

    public componentDidMount() {
        // attach the keypress event
        window.addEventListener("keypress", this._onKeyPress);
    }

    public componentDidUpdate(prevProps: IHackingProps, prevState: IHackingState) {
        if (!this.state.ready) {
            return;
        }

        if (this.state.attempts < prevState.attempts) {
            this._renderAttempts();
        }

        if ( this.state.prompt !== prevState.prompt) {
            this._renderConsole();
        }
    }

    public componentWillUnmount() {
        if (this._timerId) {
            window.clearTimeout(this._timerId);
        }
    }

    public render() {
        const charWidth = this.props.measurements.charWidth;
        
        // screen width hasn't been calculated yet;
        // nothing to render
        if (!charWidth) {
            return <div />;
        }

        const {
            headingRendered,
            attemptsRendered,
            columnsRendered,
            promptRendered,
            prompt,
            success,
            cheating,
        } = this.state;

        const style: React.CSSProperties = {
            width: charWidth * SCREEN_COLS,
        };

        if (success === true) {
            return (
                <section className="screen hacking" style={style}>
                    {this._renderPasswordAccepted()}
                </section>
            );
        }

        if (success === false) {
            return (
                <section className="screen hacking" style={style}>
                    {this._renderLockout()}
                </section>
            );
        }

        let cheatsClassName = "cheats";
        if (cheating) {
            cheatsClassName += " cheating";
        }

        return (
            <section className="screen hacking" style={style}>
                <div
                    className={cheatsClassName}
                    onClick={this._toggleCheats}
                >
                    {this._getAnswer()}
                </div>

                <header>
                    {/* screen heading */}
                    <Teletype
                        data={this._screen.content}
                        autostart={true}
                        sound={this.props.sound}
                        onDone={this._onHeadingRendered}
                        onNavigate={null}
                    />

                    {/* attempts remaining */}
                    {headingRendered && this._renderAttempts()}
                </header>

                <div className="main">
                    {/* columns */}
                    {attemptsRendered && this._renderColumns()}

                    {/* prompt */}
                    {columnsRendered && this._renderConsole()}
                </div>
            </section>
        );
    }

    private _onHeadingRendered(): void {
        this.setState({
            headingRendered: true,
        });
    }

    private _renderAttempts(): React.ReactNode {
        const {
            attempts,
            attemptsRendered,
        } = this.state;

        let text = "Attempts Remaining:";
        for (let i = 0; i < attempts; i++) {
            text += ATTEMPTS_INDICATOR;
        }

        const data: ITeletypeData[] = [
            {
                text,
                target: null,
            },

            {
                text: "",
                target: null,
            }
        ];

        return (
            <Teletype
                data={data}
                autostart={true}
                sound={this.props.sound}
                onDone={this._onAttemptsRendered}
                onNavigate={null}
                animate={!attemptsRendered}
            />
        );
    }

    private _onAttemptsRendered(): void {
        this.setState({
            attemptsRendered: true,
        });
    }

    // column rendering
    private _renderColumns(): React.ReactNode {
        // generate the hex offsets
        const data = this._generateTeletypeRowData();

        return (
            <Teletype
                data={data}
                autostart={true}
                sound={this.props.sound}
                onDone={this._onColumnsRendered}
                onNavigate={null}
                animate={!this.state.columnsRendered}
                renderStatic={this._renderStaticColumns}
            />
        );

        // return (
        //     <div className="columns">
        //         <div className="column">column 1</div>
        //         <div className="column">column 2</div>
        //     </div>
        // );
    }

    private _generateRandomCharacters(): string {
        const length = COLUMN_COUNT * COLUMN_ROWS * TEXT_ROW_LENGTH;
        const punc = "~`!@#$%^&*(){}[]/:+?<>_=;/";

        let text = "";

        for (let i = 0; i < length; i++) {
            const pos = Math.floor(Math.random() * punc.length);
            const char = punc[pos];

            text += char;
        }

        return text;
    }

    private _generateHexOffsets(): string[] {
        let offsets: string[] = [];

        // generate the base number
        const base = Math.floor(Math.random() * 9999) + 12345;

        // create the array
        const count = COLUMN_COUNT * COLUMN_ROWS;
        for (let i = 0; i < count; i++) {
            let num = base + i;
            let hex = this._padOffset(num.toString(16), 4);
            const offset = HEX_PREFIX + hex.toUpperCase();
            offsets.push(offset);
        }

        // return
        return offsets;
    }

    private _generateTeletypeRowData(): ITeletypeData[] {
        let data: ITeletypeData[] = [];

        // split the row text into MAX_TEXT_LENGTH sized segments
        const rowText = this._splitString(this._text, 12);
        const offsets = this._offsets;

        // rows and offsets should be the same length
        if (rowText.length !== offsets.length) {
            return;
        }

        for (let i = 0; i < COLUMN_ROWS; i++) {
            data.push({
                text: `${offsets[i]} ${rowText[i]} ${offsets[i + COLUMN_ROWS]} ${rowText[i + COLUMN_ROWS]} `,
                target: null,
            });
        }

        return data;
    }

    private _padOffset(hex: string, length: number): string {
        while (hex.length < length) {
            hex = "0" + hex;
        }

        return hex;
    }

    private _onColumnsRendered(): void {
        this.setState({
            columnsRendered: true,
        });
    }

    private _renderStaticColumns(): React.ReactNode {

        return (
            <div className="columns teletype-static">
                {this._renderStaticOffsetsColumn(1)}
                {this._renderStaticTextColumn(1)}

                {this._renderStaticOffsetsColumn(2)}
                {this._renderStaticTextColumn(2)}
            </div>
        );
    }

    private _renderStaticOffsetsColumn(column: number): React.ReactNode {
        const start = ((column - 1) * COLUMN_ROWS) + 1;
        const end = column * COLUMN_ROWS;

        const offsets = this._offsets.slice(start - 1, end);
        
        return (
            <div className="offsets rendered">
                {offsets.map((value: string, index: number) => {
                    let offset = value + " ";
                    if (column > 1) {
                        offset = " " + offset;
                    }
                    return <div key={index}>{offset}</div>
                })}
            </div>
        );
    }

    private _renderStaticTextColumn(column: number): React.ReactNode {
        const segmentLength = Math.floor(this._text.length / COLUMN_COUNT);
        const start = (column - 1) * segmentLength;
        const end = column * segmentLength;
        const text = this._text.substr(start, end);

        const rows = this._splitString(text, TEXT_ROW_LENGTH);
        const characters = text.split("");
        const columnOffset = (column - 1) * segmentLength;

        // when we find a word, we'll need to skip the next few characters
        let skipCount = 0;

        return (
            <div className="text rendered">
                {
                    characters.map((value, index) => {
                        if (skipCount > 1) {
                            skipCount--;
                            return;
                        }

                        // is this index the start of a word?
                        let wordIndex = this._wordIndices.indexOf(index + columnOffset);
                        if (wordIndex > -1) {
                            const word = characters.slice(index, index + this._wordLength).join("");
                            skipCount = this._wordLength;
                            return this._renderWordButton(word, index);
                        }

                        return this._renderStaticTextElement(value, index);
                    })
                }
            </div>
        );
    }

    private _renderStaticTextElement(text: string, index: number): React.ReactNode {
        let linebreak = false;
        if (index > 0 && index < this._text.length) {
            linebreak = (index + 1) % TEXT_ROW_LENGTH === 0;
        }

        return (
            <span
                className="character"
                onMouseOver={() => this._updatePrompt(text)}
                onMouseOut={() => this._updatePrompt("")}
                key={index}
            >
                {text}
                {linebreak && <br />}
            </span>
        );
    }

    private _renderWordButton(word: string, index: number): React.ReactNode {
        // split the word into an array
        let characters: any[] = word.split("");

        // determine if we need a linebreak
        // but we never want a linebreak at the start of the word
        for (let i = 1; i <= this._wordLength; i++) {
            if ((index + i) % TEXT_ROW_LENGTH === 0) {
                characters.splice(i, 0, <br key={i} />);
                break;
            }
        }

        return (
            <span
                className="word"
                key={index}
                onClick={() => this._onWordClick(word)}
                onMouseOver={() => this._updatePrompt(word)}
                onMouseOut={() => this._updatePrompt("")}
            >
                {characters}
            </span>
        );
    }

    private _onWordClick(word: string): void {
        if (!this.state.ready) {
            return;
        }

        this._checkAnswer(word);
    }

    private _checkWordLikeness(word: string): number {
        // lowercase for comparison accuracy
        word = word.toLocaleLowerCase();
        const answer = this._words[this._answer].toLocaleLowerCase();

        let count = 0;

        for (let i = 0; i < word.length; i++) {
            if (word.charAt(i) === answer.charAt(i)) {
                count++;
            }
        }

        return count;
    }

    private _checkAnswer(word: string): void {
        const count = this._checkWordLikeness(word);
        let history = this.state.history;
        let attempts = this.state.attempts;
        let messages: string[] = [`${word}`];

        // match
        if (count === this._wordLength) {
            // correct answer
            messages.push(`${word} is correct!`);
            this._onSucceed();
            
            return;
        }

        // incorrect
        messages.push(`Entry denied.`);
        messages.push(`Likeness=${count}`);
        attempts--;

        // update state
        this.setState({
            history: history.concat(messages),
            attempts,
        }, this._checkAttempts);
    }

    private _checkAttempts(): void {
        if (this.state.attempts > 0) {
            return;
        }

        this.setState({
            success: false,
        });
    }

    // prompt rendering
    private _renderConsole(): React.ReactNode {
        return (
            <section className="console" ref={this._promptRef}>
                <div className="elements">
                    {this._renderLog()}
                    {this._renderPrompt()}
                </div>
            </section>
        );
    }

    private _renderLog(): React.ReactNode {
        const history = this.state.history;
        const height = this.props.measurements.lineHeight * LOG_ROWS;

        return <Log items={history} height={height} />
    }

    private _renderPrompt(text: string = ""): React.ReactNode {
        return (
            <Prompt
                text={this.state.prompt}
                onRendered={this._onPromptRendered}
            />
        );
    }

    private _updatePrompt(text: string): void {
        this.setState({
            prompt: text,
        })
    }

    private _onPromptRendered(): void {
        this.setState({
            promptRendered: true,
            ready: true,
        });
    }

    // game preparation
    // array shuffling, cf. https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
    private _shuffle(source: any[]) {
        let copy = [...source];

        for (let i = copy.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
            [copy[i], copy[j]] = [copy[j], copy[i]]; // swap elements
        }

        return copy;
    }

    private _initializeGame(): void {
        // shuffle the words array
        this._words = this._shuffle(this._words);

        // how many characters are in each word?
        this._wordLength = this._words[0].length;

        // pick an answer
        this._answer = Math.floor(Math.random() * this._words.length);

        // generate the hex offset markers
        this._offsets = this._generateHexOffsets();

        // populate the rubbish text with real words
        this._text = this._generateText();
    }

    private _generateText(): string {
        // generate the rubbish text
        const random = this._generateRandomCharacters();
        let text = random;

        // build the word indices
        this._wordIndices = this._generateWordIndices();

        // insert the real words into the random text
        text = this._insertWords(text);

        // return the text data
        return text;
    }

    private _insertWords(text: string): string {
        const length = this._wordLength;

        // this.substr(0, index) + replacement+ this.substr(index + replacement.length);
        for (let i = 0; i < this._words.length; i++) {
            // transform the word to uppercase for aesthetics
            const word = this._words[i].toUpperCase();
            const index = this._wordIndices[i];

            const prefix = text.substr(0, index);
            const suffix = text.substr(index + length);

            text = prefix + word + suffix;
        }

        return text;
    }

    private _generateWordIndices(): number[] {
        const length = COLUMN_COUNT * COLUMN_ROWS * TEXT_ROW_LENGTH;
        const wordCount = this._words.length;
        const wordLength = this._wordLength;
        const segmentLength = Math.floor(length / wordCount);

        // split the text into even portions
        let wordIndices: number[] = [];
        const min = 2; // minimum index
        const max = segmentLength - wordLength; // maximum index

        for (let i = 0; i < wordCount; i++) {
            const segmentIndex = Math.floor(Math.random() * max) + min;
            wordIndices.push(segmentIndex + (i * segmentLength));
        }

        return wordIndices;
    }

    private _splitString(text: string, length: number): string[] {
        let results: string[] = text.match(new RegExp('.{1,' + length + '}', 'g'));
        return results;
    }

    private _getAnswer(): string {
        return this._words[this._answer];
    }

    private _onSucceed(): void {
        this.setState({
            success: true,
        });
    }

    private _onFail(): void {
        this.setState({
            success: false,
        });
    }

    private _renderPasswordAccepted(): React.ReactNode {
        return (
            <Teletype
                data={this._successScreen.content}
                autostart={true}
                sound={this.props.sound}
                onDone={this._onAcceptedRendered}
                onNavigate={null}
            />
        );
    }

    private _onAcceptedRendered(): void {
        if (this._timerId) {
            window.clearTimeout(this._timerId);
        }

        this._timerId = window.setTimeout(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess();
            }
        }, SUCCESS_TIMEOUT);
    }

    private _renderLockout(): React.ReactNode {
        return (
            <div>
                <Teletype
                    data={this._failureScreen.content}
                    autostart={true}
                    sound={this.props.sound}
                    onDone={this._onLockoutRendered}
                    onNavigate={this._resetGame}
                />
            </div>
        );
    }

    private _onLockoutRendered() {
        if (this.props.onFailure) {
            this.props.onFailure();
        }
    }

    private _onKeyPress(e: KeyboardEvent) {
        e.preventDefault();

        if (e.key === "Enter") {
            this._toggleCheats();
        }
    }

    private _toggleCheats() {
        if (!this.state.ready) {
            return;
        }

        const cheating = !this.state.cheating;

        this.setState({
            cheating,
        });
    }

    private _resetGame(): void {
        // re-setup the game
        this._initializeGame();

        // re-initialize state
        this.setState({
            attempts: 5,
            headingRendered: false,
            attemptsRendered: false,
            columnsRendered: false,
            promptRendered: false,
            ready: false,
            history: [],
            prompt: "",
            success: null,
            cheating: false,
        });
    }
}
