import React from "react";
import "./assets/css/scanlines.scss";

const Scanlines: React.FC = () => {
    return (
        <section className="scanlines" />
    );
}

export default Scanlines;
