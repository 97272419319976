import React, { Component } from "react";
import "./assets/css/ruler.scss";

const MAX_COLUMNS = 54;

interface ITapeMeasureProps {
    onMeasured?: (measurements: IMeasurements) => void;
}

export interface IMeasurements {
    charWidth: number;
    charHeight: number;
    lineHeight: number;
}

export interface ITapeMeasureState {
    screenWidth: number;
    charWidth: number;
    charHeight: number;
    lineHeight: number;

    ready: boolean;
}

export default class TapeMeasure extends Component<ITapeMeasureProps, ITapeMeasureState> {
    private _lineRef: React.RefObject<HTMLElement> = null;
    private _textRef: React.RefObject<HTMLElement> = null;
    private _text = "WWWWWWWWWW";

    private _timerId: number = null;
    private _timerInterval = 1500;
    private _attempts = 10;

    private _foo: number = null;

    constructor(props: ITapeMeasureProps) {
        super(props);

        this._lineRef = React.createRef<HTMLElement>();
        this._textRef = React.createRef<HTMLElement>();

        this.state = {
            screenWidth: null,
            charWidth: null,
            charHeight: null,
            lineHeight: null,
            ready: false,
        };

        this._onMeasured = this._onMeasured.bind(this);
    }

    public componentDidMount(): void {
        // measure once, then turn on the "ready" flag
        // that will change the classname; then we measure again
        // and keep measuring until
        // 1) the size changes; or
        // 2) we hit the timeout and just go ahead and consider it loaded
        this._measure();
    }

    public componentDidUpdate(prevProps: ITapeMeasureProps, prevState: ITapeMeasureState) {
        const {
            charWidth,
            charHeight,
            lineHeight,
            ready,
        } = this.state;

        const prevCharWidth = prevState.charWidth;
        const prevCharHeight = prevState.charHeight;
        const prevLineHeight = prevState.lineHeight;

        // if (!ready && !this._foo) {
        //     // create the timer
        //     this._foo = window.setTimeout(() => {
        //         console.log("setting ready");

        //         this.setState({
        //             ready: true,
        //         });
        //     }, 2300);
        // }

        // previous measuremenets were null
        // so we create the timer to measure things again
        if (!prevCharHeight || !prevCharWidth || !prevLineHeight) {
            if (this._timerId) {
                window.clearInterval(this._timerId);
            }

            this._setTimer();
            this._measure();
            return;
        }

        if (prevState.ready !== ready) {
            this._measure();
            return;
        }

        // dimensions have changed; we can consider the font as loaded
        if (
            (prevState.charWidth !== charWidth)
            || (prevState.charHeight !== charHeight)
            || (prevState.lineHeight !== lineHeight)
        ) {
            if (this._timerId) {
                window.clearInterval(this._timerId);
            }

            this._onMeasured();
            return;
        }
    }

    public componentWillUnmount() {
        if (this._timerId) {
            window.clearInterval(this._timerId);
        }
    }

    public render() {
        let className = "ruler";
        if (this.state.ready) {
            className += " ready";
        }

        return (
            <section className={className} ref={this._lineRef}>
                <span ref={this._textRef}>{this._text}</span>
            </section>
        );
    }

    private _measure(): void {
        // get a reference to the section
        const line = this._lineRef.current;

        // measure it
        const lineHeight = line.offsetHeight;

        // get a reference to the span
        const text = this._textRef.current;

        // measure it
        // get the width of one character by dividing
        // total width by the number of characters
        const charWidth = text.offsetWidth / this._text.length;
        const charHeight = text.offsetHeight;

        // multiple that by MAX_COLUMNS
        const screenWidth = charWidth * MAX_COLUMNS;

        // update state with that value
        console.log(
            charWidth,
            charHeight,
            lineHeight,
        );

        // since we've measured at least, we now set 'ready' to true
        this.setState({
            screenWidth,
            charWidth,
            charHeight,
            lineHeight,
            ready: true,
        });
    }

    private _onMeasured(): void {
        const {
            charHeight,
            charWidth,
            lineHeight,
        } = this.state;

        const measurements: IMeasurements = {
            charHeight,
            charWidth,
            lineHeight,
        };

        if (this.props.onMeasured) {
            this.props.onMeasured(measurements);
        }
    }

    private _setTimer(): void {
        this._timerId = window.setInterval(() => {
            // limit the number of measurements attempts
            // before we give up and assume everything
            // has loaded successfully
            if (this._attempts-- === 0) {
                this._clearTimer();
                this._onMeasured();
                return;
            }

            this._measure();
        }, this._timerInterval);
    }

    private _clearTimer(): void {
        window.clearInterval(this._timerId);
        this._timerId = null;
    }
}
