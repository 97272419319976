// pixelboard data
// each array in Pictograms is itself an array representing each pixel in a glyph
// the item's index is the X value
// the array's index is the Y value
export const Pictograms = [
    [
        [1, 0, 0, 0,], 
        [1, 0, 0, 0,], 
        [1, 0, 0, 1,], 
        [0, 1, 0, 1,], 
        [0, 0, 1, 0,],
    ],
    [
        [1, 0, 1, 1,], 
        [0, 1, 0, 0,], 
        [1, 0, 0, 0,], 
        [0, 1, 1, 1,], 
        [0, 1, 1, 1,],
    ],
    [
        [0, 0, 1, 1,], 
        [1, 1, 0, 0,], 
        [0, 0, 1, 1,], 
        [1, 0, 1, 1,], 
        [1, 1, 0, 0,],
    ],
    [
        [0, 1, 1, 0,], 
        [1, 1, 0, 1,], 
        [0, 0, 0, 0,], 
        [1, 1, 0, 1,], 
        [0, 1, 1, 0,],
    ],
    [
        [0, 0, 1, 0,], 
        [0, 1, 0, 1,], 
        [1, 0, 1, 0,], 
        [0, 1, 0, 0,], 
        [1, 0, 1, 0,],
    ],
    [
        [0, 1, 1, 0,], 
        [0, 1, 0, 0,], 
        [0, 1, 0, 0,], 
        [0, 1, 1, 1,], 
        [1, 1, 0, 0,],
    ],
    [
        [1, 1, 1, 1,], 
        [0, 0, 0, 1,], 
        [1, 1, 0, 1,], 
        [0, 0, 0, 1,], 
        [1, 1, 1, 0,],
    ],
    [
        [1, 1, 1, 1,], 
        [0, 1, 1, 0,], 
        [0, 0, 0, 0,], 
        [0, 0, 0, 0,], 
        [1, 1, 1, 1,],
    ],
]

// an empty glyph
export const Spacer = [
    [0, 0, 0, 0,],
    [0, 0, 0, 0,],
    [0, 0, 0, 0,],
    [0, 0, 0, 0,],
    [0, 0, 0, 0,],
];
