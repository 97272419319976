import React, { Component } from "react";
import Spinner from "./Spinner";
import TapeMeasure, { IMeasurements } from "./TapeMeasure";

import "./assets/css/loader.scss";

interface ILoaderProps {
    onLoad: (data: any) => void;
}

export default class Loader extends Component<ILoaderProps, any> {
    constructor(props: ILoaderProps) {
        super(props);

        this._onLoad = this._onLoad.bind(this);
    }

    public render() {
        return (
            <div className="loader">
                <Spinner />
                <TapeMeasure onMeasured={this._onLoad} />
            </div>
        );
    }

    private _onLoad(measurements: IMeasurements): void {
        console.log("loader._onload", measurements);
        this.props.onLoad(measurements as any);
    }
}