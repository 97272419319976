import React, { Component } from "react";

export interface ILogProps {
    items: string[];
    height: number;
}

export default class Log extends React.Component<ILogProps, any> {
    public render() {
        const {
            items,
            height,
        } = this.props;

        const style = {
            height,
            maxHeight: height,
        };

        return (
            <div className="log" style={style}>
                <ul>
                    {items.map((item, index) => {
                        return <li className="item" key={index}>{item}</li>;
                    })}
                </ul>
            </div>
        );
    }
}
