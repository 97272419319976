import React, { Component } from "react";

const TICK = 10;

export class Static extends Component<{}, {}> {
    private _ref: React.RefObject<HTMLCanvasElement> = null;
    private _time = 0;
    private _animateTimerId: number = null;

    constructor(props: any) {
        super(props);

        this._ref = React.createRef<HTMLCanvasElement>();
        this._animate = this._animate.bind(this);
        this._noise = this._noise.bind(this);
    }

    public componentDidMount(): void {
        this._animate();
    }

    public componentWillUnmount(): void {
        if (this._animateTimerId) {
            window.clearInterval(this._animateTimerId);
            this._animateTimerId = null;
        }
    }

    public render(): React.ReactElement {
        return <canvas width="640" height="480" ref={this._ref} />;
    }

    private _animate(): void {
        if (this._animateTimerId) {
            window.clearInterval(this._animateTimerId);
            this._animateTimerId = null;
        }
        
        this._animateTimerId = window.setInterval(this._noise, TICK);
    }

    private _noise() {
        const canvas = this._ref.current;
        const context = canvas.getContext("2d");

        const img = context.createImageData(canvas.width, canvas.height);
        const pix = img.data;

        for (let i = 0, n = pix.length; i < n; i += 4) {
            let c = 7 + Math.sin(i / 50000);
            pix[i] = pix[i + 1] = pix[i + 2] = 40 * Math.random() * c; // Set a random gray
            pix[i + 3] = 255; // 100% opaque
        }

        context.putImageData(img, 0, 0);
        this._time = (this._time + 1) % canvas.height;
    }

    /*
var makeNoise = function() {
  var imgd = context.createImageData(canvas.width, canvas.height);
  var pix = imgd.data;

  for (var i = 0, n = pix.length; i < n; i += 4) {
      var c = 7 + Math.sin(i/50000 + time/7); // A sine wave of the form sin(ax + bt)
      pix[i] = pix[i+1] = pix[i+2] = 40 * Math.random() * c; // Set a random gray
      pix[i+3] = 255; // 100% opaque
  }

  context.putImageData(imgd, 0, 0);
  time = (time + 1) % canvas.height;
}

var setup = function() {
  canvas = document.getElementById("tv");
  context = canvas.getContext("2d");
}

setup();
intervalId = setInterval(makeNoise, 50);
    */
}