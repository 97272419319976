import React, { Component } from "react";
import "../../assets/css/pictogram.scss";

interface Props {
    title?: string;
    text: string;
    className?: string;
    buttonLabel?: string;

    onClick: () => void;
}

export class Dialog extends Component<Props, {}> {
    public render(): React.ReactElement {
        const {
            className,
            title,
            text,
        } = this.props;

        let dialogClass = ["dialog"];
        if (className) {
            dialogClass.push(className);
        }

        return (
            <div className={dialogClass.join(" ")}>
                {title && <h3>{title}</h3>}
                <p>{text}</p>
                {this._renderButton()}
            </div>
        );
    }

    private _renderButton(): React.ReactNode {
        const {
            buttonLabel,
            onClick,
        } = this.props;

        const label = buttonLabel || "OK";

        const handleTouchStart = (e: React.TouchEvent<HTMLButtonElement>) => {
            if (onClick) {
                onClick();
            }
        };

        const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            e.currentTarget.blur();

            if (onClick) {
                onClick();
            }
        };

        // prevent click event
        const handleTouchEnd = (e: React.TouchEvent<HTMLButtonElement>) => {
            e.currentTarget.blur();
            e.preventDefault();
        };

        return (
            <button
                className="button"
                onClick={handleClick}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
            >
                <span className="chevron">&gt;</span>
                <span>{label.toUpperCase()}</span>
                <span className="chevron">&lt;</span>
            </button>
        );
    }
}
