import React, { Component } from "react";

export interface Props {
    data: number[][];
    scale?: number;
    foreground?: string;
    background?: string;
}

export class Pixelboard extends Component<Props, {}> {
    private _ref: React.RefObject<HTMLCanvasElement> = null;
    private _scale: number = null;
    private _width: number = null;
    private _height: number = null;
    private _background: string = null;
    private _foreground: string = null;

    constructor(props: Props) {
        super(props);

        const {
            data,
            scale,
            foreground,
            background,
        } = this.props;

        this._ref = React.createRef<HTMLCanvasElement>();

        this._scale = scale || 10;
        this._width = data[0].length;
        this._height = data.length;

        // colors
        this._foreground = foreground || "rgb(171, 192, 183)";
        this._background = background || "rgba(8, 7, 6, 1)";
    }

    public componentDidMount(): void {
        this._draw();
    }

    public componentDidUpdate(): void {
        this._draw();
    }

    public render(): React.ReactElement {
        return (
            <canvas
                ref={this._ref}
                width={this._width * this._scale}
                height={this._height * this._scale}
            />
        );
    }

    private _draw() {
        if (!this._ref) {
            return;
        }

        const fg = this._foreground;
        const bg = this._background;

        const canvas = this._ref.current;
        const context = canvas.getContext("2d");

        let i = 0;
        let x = 0;
        let y = 0;
        let px = this.props.data;

        for (y = 0; y < px.length; y++) {
            const row = px[y];
            for (x = 0; x < row.length; x++) {
                const px = row[x];
                context.fillStyle = px === 0 ? bg : fg;
                context.fillRect(x * this._scale, y * this._scale, this._scale, this._scale);
            }
        }
    }
}